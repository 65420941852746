<template>
    <div>
        <CModalExtended
            :title="titleModalImdgClass"
            size="lg"
            class="modal-extended"
            color="dark"
            :closeOnBackdrop="false"
            :show.sync="modalClassActive"
            @update:show="$emit('close-modal-imdg-class');"
        >
            <CRow>
                <CCol sm="12">
                    <CRow>
                        <CCol sm="12" lg="12" xl="12">
                            <CTextarea
                                rows="2"
                                :label="$t('label.name')+' '+'(ES)'" 
                                addLabelClasses="text-right required"
                                :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                :placeholder="$t('label.imdgClassName')+' '+'(ES)'"
                                v-uppercase
                                :is-valid="hasError($v.formImdgClass.ImdgClassNameEs)"
                                :invalid-feedback="errorMessage($v.formImdgClass.ImdgClassNameEs)"
                                v-model="$v.formImdgClass.ImdgClassNameEs.$model"
                            />            
                        </CCol>  
                        <CCol sm="12" lg="12" xl="12">
                            <CTextarea
                                rows="2"
                                :label="$t('label.name')+' '+'(EN)'"
                                addLabelClasses="text-right required"
                                :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                :placeholder="$t('label.imdgClassName')+' '+'(EN)'"
                                v-uppercase
                                :is-valid="hasError($v.formImdgClass.ImdgClassNameEn)"
                                :invalid-feedback="errorMessage($v.formImdgClass.ImdgClassNameEn)"
                                v-model="$v.formImdgClass.ImdgClassNameEn.$model"
                            />            
                        </CCol>                           
                        <CCol sm="12" lg="12" xl="12">
                            <CInput
                                :label="$t('label.class')"
                                placeholder=0
                                type="number"
                                min="0"
                                max="99"
                                v-model="$v.formImdgClass.ImdgClassAbbrev.$model"
                                :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                addLabelClasses="text-right required"
                                :is-valid="hasError($v.formImdgClass.ImdgClassAbbrev)"
                                :invalid-feedback="errorMessage($v.formImdgClass.ImdgClassAbbrev)"
                            />
                        </CCol>
                        <CCol sm="12" lg="12" xl="12">
                            <CSelect
                                :label="$t('label.imdgDivision')"
                                :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                add-label-classes="required text-right"
                                v-model="$v.formImdgClass.FgActRequieredSub.$model"
                                :value.sync="formImdgClass.FgActRequieredSub"
                                :options="applyClassImdgOptions"
                                :is-valid="hasError($v.formImdgClass.FgActRequieredSub)" 
                                :invalid-feedback="errorMessage($v.formImdgClass.FgActRequieredSub)"
                                @blur="$v.formImdgClass.FgActRequieredSub.$touch()"
                            />
                        </CCol>
                        <CCol sm="12" lg="12" xl="12" v-if="isEditImdgClass">
                            <CSelect
                                :label="$t('label.status')"
                                :value.sync="formImdgClass.Status"
                                :horizontal="{label:'col-sm-12 col-lg-3 col-xl-3', input:'col-sm-12 col-lg-8 col-xl-8'}"
                                addLabelClasses="text-right required"
                                v-model="formImdgClass.Status"
                                :options="selectOptions"
                                :is-valid="formImdgClass.Status" 
                            />
                        </CCol>                
                    </CRow>
                </CCol>
            </CRow>
            <template #footer >
                <CButton
                    color="add"
                    class="d-flex align-items-center"
                    :disabled="apiStateLoading"
                    @click.stop="changeStatus()"
                >
                    <CIcon name="checkAlt" />{{$t('button.accept')}}
                </CButton>
                <CButton
                    color="wipe"
                    @click="closeModal"
                    class="m-2"
                    :disabled="apiStateLoading"
                >
                    <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </template>
        </CModalExtended>
    </div>
</template>

<script>
    import UpperCase  from '@/_validations/uppercase-directive';
   // import GeneralMixin from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';
    import ImdgValidation from '@/_validations/imdg/imdgValidation';
    
    //caso excepcional
   // const VUE_APP_PATH = `${this.$store.getters["connection/getBase"]}`;

    //data
    function data() {
        return {
            activeTab: 0,
            apiStateLoading: false,
            modalClassActive: false,
            isEditImdg: false,
            isEditImdgClass: false,
            loadingImdgTable:false,
            imdgItems:[],
            selectImdgClass:[],
            disabled: 0,
            //imdgClass
            formImdgClass:{
                ImdgClassNameEs: "",
                ImdgClassNameEn: "",
                ImdgClassAbbrev: "",
                Status: true,
                FgActRequieredSub: '',
                FgActImdgClass: true,
            },
            imdgClassItems:[],
          
        }
    }

    //methods  
    function closeModal() {
        this.modalClassActive = false
        this.$emit("close-modal-imdg-class", this.modalClassActive);
        this.resetImdgClassForm();
    }

    function resetImdgClassForm () {
        this.formImdgClass = {
            ImdgClassId: "",
            ImdgClassNameEs: "",
            ImdgClassNameEn: "",
            ImdgClassAbbrev: "",
            Status: true,
            FgActImdgClass: true,
            FgActRequieredSub: true,
        };
        this.$v.formImdgClass.$reset();
        this.isEditImdgClass = false;
    }

    function setImdgClassForm (imdgClassData) {
        this.formImdgClass = {
            ImdgClassId: imdgClassData.ImdgClassId, 
            ImdgClassNameEs: imdgClassData.ImdgClassNameEs, 
            ImdgClassNameEn: imdgClassData.ImdgClassNameEn, 
            ImdgClassAbbrev: imdgClassData.ImdgClassAbbrev,  
            Status: imdgClassData.FgActImdgClass,
            FgActImdgClass: imdgClassData.FgActImdgClass,
            FgActRequieredSub: imdgClassData.FgActRequieredSub,
        };
        this.isEditImdgClass = true;
        this.$v.$touch();
    }
   
   
    async function submitImgClass() {
        try {
            this.$v.$touch();
            if (this.$v.formImdgClass.$error) {
                throw this.$t('label.errorsPleaseCheck');
            }
            
            const ImdgClassJson ={
                ImdgClassNameEs: this.formImdgClass.ImdgClassNameEs,
                ImdgClassNameEn: this.formImdgClass.ImdgClassNameEn,
                ImdgClassAbbrev: this.formImdgClass.ImdgClassAbbrev,
                FgActRequieredSub: this.formImdgClass.FgActRequieredSub,
            };
            if(this.isEditImdgClass){
                ImdgClassJson.ImdgClassId = this.formImdgClass.ImdgClassId;
                ImdgClassJson.Status = this.formImdgClass.Status;
            }
            let method = this.isEditImdgClass?'PUT':'POST';
            let ruta = this.isEditImdgClass?'ImdgClass-update':'ImdgClass-insert';
            this.$http.ejecutar(method, ruta, ImdgClassJson, { root: 'ImdgClassJson' })
                .then((response) => {
                    if ((response.status = 200 || 201)) {
                        const messageSuccess = response.data.data[0].Response;
                        this.notifySuccess({text: messageSuccess})
                        this.resetImdgClassForm();
                        this.$emit('updated-imdg-class-list');
                        this.closeModal();
                    }
                }).catch((e) => {
                    this.notifyError({text: e});
                });
        } catch (e) {
            this.notifyError({text: e});
        }
    }

    function changeStatus() {
        if(this.formImdgClass.FgActImdgClass !== '' && this.formImdgClass.ImdgClassId !== 0){
            if(this.formImdgClass.FgActImdgClass !== this.formImdgClass.Status){              
                    this.$swal
                        .fire(this.alertProperties({
                            text: `${this.$t('label.changeStatusQuestion')+'?'}`,
                        }))     
                    .then((result) => {
                    if (result.isConfirmed) {
                        this.submitImgClass();
                    }
                })
            }else{
                this.submitImgClass();
            }
        }else{
            this.submitImgClass();
        }
    }

    //computed
    function selectOptions() {
        return[
        {
            value: true,
            label: this.$t('label.ACTIVO'),
        },
        {
            value: false,
            label: this.$t('label.INACTIVO'),
        },
        ]
    }

    function applyClassImdgOptions() {
        return[
             { 
                value: '', 
                label: this.$t('label.select')
            },
            { 
                value: true, 
                label: this.$t('label.apply')
            },
            { 
                value: false, 
                label: 'N/A'
            }
        ]
    
    }

    //watch
    export default {
        name: 'modal-imdg-class',
        data,
        mixins: [ModalMixin],
        props: {
            modal: {
                type: Boolean,
                default: false,
            },
            modalEdit:{
                type: Boolean,
                default: false,
            },
            imdgClassData: {
                type: Object,
                default: () => { }
            },
            titleModalImdgClass:{ 
                type: String,
                default: false,
            }
        },
        components: {
        },
        directives: UpperCase,
        validations:ImdgValidation,
        methods:{
            closeModal,
            resetImdgClassForm,
            setImdgClassForm,
            submitImgClass,
            changeStatus
        },
        computed: {
            selectOptions,
            applyClassImdgOptions
        },
        watch:{
            modal: function(val){
                this.modalClassActive = val;
                if(val){
                    if (this.modalEdit)
                        this.setImdgClassForm(this.imdgClassData);
                }else{
                    this.closeModal();
                };
            },
            /*idState*/
        }
    }
</script>

<style lang="scss">
    
</style>

<template>
  <div :class="wrapperClasses">
    <div :class="navWrapperClasses">
      <CustomDistributor 
        tag="ul"
        class="no-space"
        :class="navClasses" 
        :header="true"
        :changeTabTo="changeTabTo"
      >
        <slot></slot>
      </CustomDistributor>
    </div>
    <div :class="tabsWrapperClasses">
      <CustomDistributor
        :class="tabsClasses" 
        :addClasses="addTabClasses"
      >
        <slot></slot>
      </CustomDistributor>
    </div>
  </div>
</template>

<script>
import CustomDistributor from './CustomDistributor'
export default {
  name: 'CustomTabs',
  components: {
    CustomDistributor
  },
  provide () {
    const tabs = {}
    Object.defineProperty(tabs, 'activeTab', {
      get: () => this.activeTabIndex
    })
    Object.defineProperty(tabs, 'scale', {
      get: () => this.scale
    })
    return { tabs, initialTab: this.activeTab }
  },
  props: {
    scale: {
      type: Boolean,
      default: true
    },
    addNavWrapperClasses: [String, Array, Object],
    addNavClasses: [String, Array, Object],
    addTabsWrapperClasses: [String, Array, Object],
    addTabsClasses: [String, Array, Object],
    addTabClasses: [String, Array, Object],
    activeTab: Number
  },
  data () {
    return {
      activeTabIndex: this.activeTab || 0
    }
  },
  watch: {
    activeTab (val) {
      this.activeTabIndex = val || 0
    }
  },
  computed: {
    wrapperClasses () {
      return {}
    },
    navWrapperClasses () {
      return [ this.addNavWrapperClasses]
    },
    navClasses () {
      return [
        this.addNavClasses,
        {
          'custom-nav': true
        }
      ]
    },
    tabsClasses () {
      return [
        this.addTabsClasses,
        'tab-content',
        'custom-tab-content'
      ]
    },
    tabsWrapperClasses() {
      return [
        this.addTabsWrapperClasses
      ];
    }
  },
  methods: {
    changeTabTo (tab) {
      this.activeTabIndex = tab
      this.$emit('update:activeTab', tab)
    }
  }
}
</script>

<style>

.custom-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
}
.custom-nav a {
  text-decoration: none;
}
.custom-nav .custom-tab-link {
  background: #636f83;
  display: inline-block;
  color: #fff;
  line-height: 49px;

  margin: 0 5px 5px 0;
  padding: 0 20px;
  border: 0;
  outline: none;
  cursor: pointer;
  opacity: 0.8;

  border-radius: 15px;

  transition: all 0.1s;
  -o-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -webkit-transition: all 0.1s;
}
.custom-nav li.active > .custom-tab-link {
  background: #fff;
  color: #000;
  opacity: 1;
  cursor: default;

  margin-bottom: 0;
  padding-bottom: 5px;
  border-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top: #636f83 2px solid;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-nav a:hover {
  background: #636f83;
  opacity: 1;
}
.custom-tab-content {
  background: #fff;
  border: 0;
  outline: none;

  border-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 0 3px rgba(0, 0, 0, 0.2);
}

.no-space {
  padding: 0;
}
</style>